import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div class="Section Footer">
                <p>Copyright &#169; 2021 Rishi Mirchandani </p>
            </div>
        )
    }
}

export default Footer;